<template>
  <v-card v-if="group">
    <v-card-title>
      {{ group.Navn }}
      <v-btn
        text
        @click="dialogEditName = true"
        v-if="!readOnlyGroup"
        data-testid="editNameGroupDetails"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle v-if="!readOnlyGroup">
      <div class="mt-4">
        <v-alert type="info" outlined v-if="groupHasCodword">
          <GroupShortname :group="group" />
          <div v-if="group.KodeNavn">
            Gruppenavn <i>{{ group.Navn }}</i> vil vises i velkomst-SMS ved
            innmelding
          </div>
        </v-alert>
        <br />
      </div>
    </v-card-subtitle>
    <v-card-text>
      <div v-if="readOnlyGroup">
        <v-alert type="info" dense outlined>
          {{ $t("groupExternalImportInfo") }}
        </v-alert>
      </div>

      <v-row class="mt-2 mb-n8">
        <v-col cols="12">
          <div class="float-right">
            <DepartmentSelect
              v-if="!readOnlyGroup"
              :init-department="group.Avdeling"
              :optional-hint="true"
              v-on:change="setDepartment"
              :dense="true"
              id="department-select"
              :label="$t('belongsToDepartment')"
              :ask-for-user-confirm="true"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ContactList
            :group-id="group.Id"
            :title="$t('members')"
            :read-only-group="readOnlyGroup"
            data-testid="contactListGroupDetails"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="dialogDelete = true"
        class="error"
        small
        v-if="deleteButton && !readOnlyGroup"
        data-testid="deleteGroupDetails"
      >
        {{ $t("deleteGroup") }}
      </v-btn>
      <v-spacer />
      <v-btn @click="close" v-if="closeBtn" data-testid="closeGroupDetails">
        {{ $t("close") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogEditName" max-width="500">
      <EditName
        :init-name="this.group.Navn"
        v-on:cancel="dialogEditName = false"
        v-on:done="setName"
      />
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="700">
      <v-card :loading="deleting">
        <v-card-title>
          {{ $t("confirm") }}
        </v-card-title>
        <v-card-text>
          {{ $t("deleteGroupWarning") }}
        </v-card-text>
        <v-card-actions>
          <div id="delete-option">
            <GroupMembersDeleteOption
              v-if="deleteContactsOk && initDeleteContactsValue !== null"
              :init-value="initDeleteContactsValue"
              v-on:change="setDeleteContacts"
            />
          </div>
          <v-spacer />
          <v-btn
            @click="dialogDelete = false"
            :disabled="deleting"
            data-testid="cancelDeleteGroupDetails"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            @click="deleteGroup"
            :disabled="deleting"
            class="error"
            data-testid="okDeleteGroupDetails"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogForbidden" max-width="700">
      <UserError v-on:ok="dialogForbidden = false">
        <template v-slot:text>
          {{ $t("cannotDeleteScenarioGroup") }}
        </template>
      </UserError>
    </v-dialog>
  </v-card>
</template>

<script>
import ContactList from "../contacts/ContactList";
import apiGroup from "../../api/v24/api.group";
import UserConfirm from "../misc/UserConfirm";
import store from "../../store";
import UserError from "@/components/misc/UserError";
import DepartmentSelect from "@/components/departments/DepartmentSelect";
import EditName from "@/components/misc/EditName";
import GroupMembersDeleteOption from "@/components/groups/GroupMembersDeleteOption.vue";
import GroupShortname from "@/components/groups/GroupShortname.vue";

export default {
  name: "GroupDetails",
  components: {
    GroupShortname,
    GroupMembersDeleteOption,
    EditName,
    DepartmentSelect,
    UserError,
    UserConfirm,
    ContactList
  },

  props: {
    groupId: {
      type: Number,
      required: true
    },
    closeBtn: {
      type: Boolean
    },
    deleteButton: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    userIsAdmin() {
      return store.getters.getUserIsAdmin;
    },

    deleteContactsOk() {
      return (
        store.getters.getUserIsAdmin ||
        !store.state.userProfile.Bruker?.Avdeling
      );
    },

    groupHasCodword() {
      return !!this.group?.KodeNavn;
    }
  },

  data: () => ({
    group: null,
    dialogEditName: false,
    dialogDelete: false,
    dialogForbidden: false,
    deleteContacts: false,
    deleting: false,
    readOnlyGroup: false,
    initDeleteContactsValue: null
  }),

  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.group = null;
        apiGroup.get(this.groupId).then(group => {
          if (!group) {
            store.commit("setAppSnackbar", this.$t("group404"));
            store.dispatch("updateGroups");
          } else {
            this.group = group;
            this.checkIfReadOnly();
            this.initDeleteContactsValue = !!group.KodeNavn;
          }
        });
      }
    }
  },

  methods: {
    save() {
      const g = this.group;
      return apiGroup.save(g.Id, g.Navn, g.Avdeling, g.KodeNavn).then(() => {
        store.dispatch("updateGroups");
      });
    },
    close() {
      this.$emit("close");
    },
    checkIfReadOnly() {
      if (this.group.Import || this.group.AdSync) {
        this.readOnlyGroup = true;
      } else {
        this.readOnlyGroup = false;
      }
    },
    setName(name) {
      this.group.Navn = name;
      this.save().then(() => {
        this.dialogEditName = false;
        store.commit("setAppSnackbar", this.$t("nameChanged"));
      });
    },
    setDepartment(departmentId) {
      this.group.Avdeling = departmentId;
      this.save().then(() => {
        store.commit("setAppSnackbar", this.$t("departmentChanged"));
      });
    },
    setDeleteContacts(state) {
      this.deleteContacts = state;
    },
    deleteGroup() {
      this.deleting = true;
      apiGroup
        .delete(this.group.Id, this.deleteContacts)
        .then(() => {
          store.commit("setAppSnackbar", this.$t("groupDeleted"));
          store.dispatch("updateGroups");
          this.$emit("delete");
        })
        .catch(err => {
          if (err._forbidden) {
            this.dialogForbidden = true;
          }
          if (err._notFound) {
            // Group was already deleted. Don't bother the user with error message, we just emit delete event so
            // parent component can do UI state change as if it was deleted now
            this.$emit("delete");
          }
        })
        .finally(() => {
          this.dialogDelete = false;
          this.deleting = false;
        });
    }
  }
};
</script>
<style scoped>
#delete-option,
#department-select {
  max-width: 400px;
}
</style>
